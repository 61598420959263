import HomeIcon from '@mui/icons-material/Home';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';


export const MOBILE_MENU = [
    {
        name: "Accueil",
        icon: <HomeIcon sx={{color: 'white'}} />,
        link: "/"
    },
    {
        name: "A propos",
        icon: <HelpOutlineIcon sx={{color: 'white'}} />,
        link: "/about"
    },
]