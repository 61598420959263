export const SERVICES = [
    {
        id: 1,
        image_url: "assets/images/service/service2.jpg",
        title: "Développement Web/Mobile",
        description: "Création des applications performantes pour une expérience utilisateur optimale.",
        icon: "assets/images/service/service-style2.png",
    },
    {
        id: 2,
        image_url: "assets/images/service/service10.jpg",
        title: "Gestion de projets",
        description: "Une coordination efficace de vos projets pour atteindre vos objectifs en toute simplicité.",
        icon: "assets/images/service/service-style1.png",
    },
    {
        id: 3,
        image_url: "assets/images/service/service11.jpg",
        title: "Big Data & Analysis",
        description: "Transformez vos données en informations exploitables pour une meilleurs prise de décision.",
        icon: "assets/images/service/service-style11.png",
    },
    {
        id: 4,
        image_url: "assets/images/service/service13.jpg",
        title: "Marketing digital",
        description: "Augmentez votre visibilité en ligne et boostez votre croissance.",
        icon: "assets/images/service/service-style13.png",
    },
    {
        id: 5,
        image_url: "assets/images/service/service3.jpg",
        title: "UI/UX Design",
        description: "Améliorez votre expérience utilisateur et la convivialité de votre site web.",
        icon: "assets/images/service/service-style3.png",
    },
    {
        id: 6,
        image_url: "assets/images/service/service12.jpg",
        title: "Cyber sécurité",
        description: "Protégez vos données et votre entreprose avec nos solutions professionnelles.",
        icon: "assets/images/service/service-style12.png",
    },
];

export const PROJECTS = [
    {
        id: 1,
        image_url: "assets/images/project/project1-old.png",
        nom: "KANUYA",
        description1: "Aide au démunis via la collecte de fonds",
        description2: "",
    },
    {
        id: 2,
        image_url: "assets/images/project/project1.png",
        nom: "LEWACHA",
        description1: "Une intelligence artificielle comme tuteur",
        description2: "",
    },
    {
        id: 3,
        image_url: "assets/images/project/project6.png",
        nom: "DOC CONNECT",
        description1: "Planification intuitive",
        description2: "Notifications avancées",
    },
    {
        id: 4,
        image_url: "assets/images/project/project5.png",
        nom: "",
        description1: "",
        description2: "",
    },
    {
        id: 5,
        image_url: "assets/images/project/project3.png",
        nom: "GIGANET",
        description1: "Connectivité pour tous à prix abordable",
        description2: "",
    },
    {
        id: 6,
        image_url: "assets/images/project/project2.png",
        nom: "HOME DOCTOR",
        description1: "Education à la santé",
        description2: "Coûts des soins bas",
    }
];

export const PARTNERS = [
    {
        id: 1,
        logo: ""
    }
];

export const TEAMS = [
    {
        id: 1,
        name: "Ivan SIEWE",
        occupation: "Chef équipe développeur",
        picture: "assets/images/team/team4.png"
    },
    {
        id: 2,
        name: "Kevin TESSA",
        occupation: "UI/UX Designer",
        picture: "assets/images/team/team4.png"
    },
    {
        id: 3,
        name: "Marc TSOBGNY",
        occupation: "Développeur FullStack",
        picture: "assets/images/team/team4.png"
    },
    {
        id: 3,
        name: "Furel TEGUIMENE",
        occupation: "Développeur FullStack",
        picture: "assets/images/team/team5.jpeg"
    },
];