import React from "react";
import { Link } from "react-router-dom";
import { PROJECTS } from "../datas/dataSources";

const Projects = () => {
  return (
    <div id="projets" className="project-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title text-center">
              <div className="section-sub-title">
                <h1 className="text-primary text-uppercase">Nos Projets</h1>
              </div>
              <div className="section-main-title">
                <p className="fs-5 mt-sm-4">
                  Nous proposons plusieurs solutions qui contribuent au
                  quotidien à l'amélioration des performances des entreprises et
                  qui impactent positivement dans la vie des populations.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="column"> */}
            {PROJECTS.length > 0 &&
              PROJECTS.map((proj) => {
                return (
                  <div key={proj?.id} className="col-sm-4 project-thumb shadow-sm rounded-3 ">
                    <Link>
                      <img
                        src={proj?.image_url}
                        alt=""
                        style={{ width: "100%" }}
                      />
                      <div className="portfolio-content">
                        <div className="protfolio-title">
                          <h5 className="text-white">{proj?.nom}</h5>
                          <p className="text-white mt-4 p-0 m-0 fs-4">
                            {proj?.description1}
                          </p>
                          <p className="text-white mt-2 fs-4">
                            {proj?.description2}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
            {/* <div className="project-thumb shadow">
              <Link>
                <img
                  src="assets/images/project/project1-old.png"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="portfolio-content">
                  <div className="protfolio-title">
                    <h5 className="text-white">KANUYA</h5>
                    <p className="text-white mt-4 p-0 m-0 fs-4">
                      Aide au démunis via la collecte de fonds
                    </p>
                    <p className="text-white mt-2 fs-4">
                      
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="project-thumb shadow rounded-4">
              <Link>
                <img
                  src="assets/images/project/project1.png"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="portfolio-content">
                  <div className="protfolio-title">
                    <h5 className="text-white">LEWACHA</h5>
                    <p className="text-white mt-4 p-0 m-0 fs-4">
                      Solution cloud, sécurisée
                    </p>
                    <p className="text-white mt-2 fs-4">
                      Une intelligence artificielle comme tuteur
                    </p>
                  </div>
                </div>
              </Link>
            </div> */}
          </div>
          {/* <div className="column">
            <div className="project-thumb shadow ">
              <Link>
                <img
                  src="assets/images/project/project6.png"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="portfolio-content">
                  <div className="protfolio-title">
                    <h5 className="text-white">DOC CONNECT</h5>
                    <p className="text-white mt-4 p-0 m-0 fs-4">
                      Planification intuitive
                    </p>
                    <p className="text-white mt-2 fs-4">
                      Notifications avancées
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="project-thumb">
              <Link>
                <img
                  src="assets/images/project/project5.png"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="portfolio-content">
                  <div className="protfolio-title">
                    <h6></h6>
                    <h3>
                      <a href="#"></a>
                    </h3>
                    <h3>
                      <a href="#"></a>
                    </h3>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="column">
            <div className="project-thumb shadow">
              <Link>
                <img
                  src="assets/images/project/project3.png"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="portfolio-content">
                  <div className="protfolio-title">
                    <h5 className="text-white">GIGANET</h5>
                    <p className="text-white mt-4 p-0 m-0 fs-4">
                      Connectivité pour tous à prix abordable
                    </p>
                    <p className="text-white mt-2 fs-4">
                      Notifications avancées
                    </p>
                  </div>
                </div>
              </Link>
            </div>
            <div className="project-thumb shadow">
              <Link>
                <img
                  src="assets/images/project/project2.png"
                  alt=""
                  style={{ width: "100%" }}
                />
                <div className="portfolio-content">
                  <div className="protfolio-title">
                    <h5 className="text-white">HOME DOCTOR</h5>
                    <p className="text-white mt-4 p-0 m-0 fs-4">
                      Education à la santé
                    </p>
                    <p className="text-white mt-2 fs-4">Coûts des soins bas</p>
                  </div>
                </div>
              </Link>
            </div>
          </div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default Projects;
