import React from 'react'
import { SERVICES } from '../datas/dataSources'

const Service = () => {
    return (
        <div id="services" className="service-area style-two">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title text-center">
                            <div className="section-sub-title">
                                <h1 className="text-primary text-uppercase mb-sm-5">Nos Services</h1>
                            </div>
                            <div className="section-main-title">
                                <p className="fs-5 mt-sm-4">Fournir des services créatifs pour la croissance de votre entreprise</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {
                        SERVICES.length > 0 && SERVICES.map((service) => {
                            return (
                                <div key={service?.id} class="col-lg-4 col-md-6 shadow-sm">
                                    <div class="service-items-four">
                                        <div class="service-four-thumb rounded-2">
                                            <img src={service?.image_url} alt="" />
                                        </div>
                                        <div class="service-four-content">
                                            <div class="service-four-title py-3">
                                                <h4><a href="services/details">{service?.title}</a></h4>
                                                <p>{service?.description}</p>
                                            </div>
                                            <div class="px-2">
                                                <img height={48} src={service?.icon} alt="" />
                                            </div>
                                        </div>
                                        <div class="service-four-btn">
                                            <a href="services/details">Plus de détails <i class="bi bi-arrow-right"></i></a>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Service
