import React from 'react'

const LogoText = () => {
    return (
        <a href="/" className='text-decoration-none'>
            <a href="/"><img width={60} src="assets/images/Logo2.png" alt="" /></a>
            <span className="fs-5 text-white"><span className="fw-bold ms-2">A</span>l<span className="fw-bold">S</span>adows <span className="fw-bold">T</span>echnologies</span>
        </a>
    )
}

export default LogoText
