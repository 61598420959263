import React from 'react'
import { TEAMS } from '../datas/dataSources'

const Team = () => {
    return (
        <div className="team-area style-two">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="section-main-title team2">
                            <h2>Travailler avec une</h2>
                            <h2>Excéllente équipe</h2>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="team-discription">
                            <p>Nous fournissons des services de référencement pour aider votre site Web à être mieux classé sur les moteurs de recherche comme Google.</p>
                        </div>
                    </div>
                </div>
                <div className='row justify-content-center mb-sm-4'>
                    <div className="col-md-6 col-xl-4 shadow-sm">
                        <div className="">
                            <div className="text-center">
                                <img className='w-100' src="assets/images/team/team7.png" alt="" />
                            </div>
                            <div className="team-item-content text-center">
                                <h4><a href="#">Ousmanou MOHAMED</a></h4>
                                <span>Co-fondateur, CEO</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">

                    {
                        TEAMS.length > 0 && TEAMS.map((team) => {
                            return (
                                <div key={team?.id} className="col-md-6 col-xl-3 shadow-sm mt-3 mt-sm-none">
                                    <div className="team-items-box">
                                        <div className="team-item-thumb"
                                            style={{
                                                width: '100%',
                                                height: '303px',
                                                backgroundImage: `url('${team?.picture}')`,
                                                backgroundSize: "contain",
                                                backgroundRepeat: "no-repeat",
                                                backgroundPosition: "center",
                                            }}
                                        >
                                            {/* <img width={306} height={303} src={team?.picture} alt="" /> */}
                                        </div>
                                        <div className="team-item-content">
                                            <h4><a href="#">{team?.name}</a></h4>
                                            <span>{team?.occupation}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Team
