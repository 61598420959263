import * as React from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import CloseIcon from '@mui/icons-material/Close';
import { MOBILE_MENU } from '../utils/Constains';
import LogoText from './LogoText';

const DrawerMenu = () => {
    // const [state, setState] = React.useState({
    //     top: false,
    //     left: false,
    //     bottom: false,
    //     right: false,
    // });

    const [open, setOpen] = React.useState(false)

    const toggleDrawer = (open) => (event) => {
        if (
            event &&
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        setOpen(open)
    };


    React.useEffect(() => { }, [open])

    return (
        <div>
            {/* {['left', 'right', 'top', 'bottom'].map((anchor) => ( */}
            <React.Fragment>
                <Button onClick={toggleDrawer(true)}>
                    {/* <div className="side-menu-info">
                        <div className="sidebar-menu "> */}
                    <a className="navSidebar-button" href="#"><i className="bi bi-justify-right"></i></a>
                    {/* </div>
                    </div> */}
                </Button>
                <SwipeableDrawer
                    anchor={"right"}
                    open={open}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                >
                    <Box
                        sx={{ color: 'white' }}
                        role="presentation"
                        onClick={toggleDrawer(false)}
                        onKeyDown={toggleDrawer(false)}
                    >
                        <List>
                            <div className='d-flex justify-content-between align-items-center m-3'>
                                <LogoText />
                                <div role='button' onClick={toggleDrawer(false)}>
                                    <CloseIcon sx={{ fontSize: '2.5rem' }} />
                                </div>
                            </div>
                            <div className='pt-4'>

                                {MOBILE_MENU?.length > 0 && MOBILE_MENU.map((menu, index) => (
                                    <ListItem key={index} disablePadding>
                                        <ListItemButton href={`${menu?.link}`} sx={{ backgroundColor: 'rgba(1, 84, 247, 0.9)' }} className='p-3 m-2 rounded-2'>
                                            <ListItemIcon>
                                                {menu?.icon}
                                            </ListItemIcon>
                                            <span className='fs-4'>{menu?.name}</span>
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </div>
                        </List>
                    </Box>
                </SwipeableDrawer>
            </React.Fragment>
            {/* ))} */}
        </div>
    )
}

export default DrawerMenu
