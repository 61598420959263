import React from 'react'
import LogoText from '../components/LogoText'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footer-area">
            <div className="container">
                <div className="row gx-6">
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-wiget">
                            <div className="footer-wiget-log">
                                <LogoText />
                            </div>
                            <div className="footer-wiget-text">
                                <h4>Vous pouvez nous contacter à tout moment.</h4>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-wiget">
                            <div className="footer-wiget-title">
                                <h4>Autres pages</h4>
                            </div>
                            <div className="footer-wiget-menu">
                                <ul>
                                    <li><Link to={"/"}> Accueil</Link></li>
                                    <li><Link to={"/about"}> A propos</Link></li>
                                    <li><a href="/#services"> Services</a></li>
                                    <li><a href="/#projets"> Projets</a></li>
                                    <li><Link to={"/contact"}> Contact</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div className="footer-wiget">
                            <div className="footer-wiget-title">
                                <h4>Liens rapides</h4>
                            </div>
                            <div className="footer-wiget-menu">
                                <ul>
                                    <li><a href="#"> Faq</a></li>
                                    <li><a href="#"> Tearm of service</a></li>
                                    <li><a href="#"> Disclaimer</a></li>
                                    <li><a href="#"> Privacy Policy</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 pr-0">
                        <div className="foter-box">
                            <div className="footer-wiget-icon">
                                <ul>
                                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                            <div className="footer-widget-contct-menu">
                                <ul>
                                    <li><a href="#">( +237 ) 620 83 75 57</a></li>
                                    <li className="line"><a href="#">contact@alshadowsgroup.com</a></li>
                                    <li><a href="#">Yaoundé, Cameroun, Ancienne Sonel Mimbman</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-100">
                    <div className="col-lg-6 col-md-12">
                        <div className="copyright-text">
                            <p>Copyright © <a href="#">Alshadows Technologies</a> All Rights Reserved.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="footer-condition">
                            <ul>
                                <li><a href="#">Terms of service</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
