import React from 'react'
import Breatcom from '../components/Breatcom'
import RaisonOfChoose from './RaisonOfChoose'
import Service from './Service'
import Team from '../components/Team'

const About = () => {
    return (
        <div>
            <Breatcom pageName={"A propos"} />
            <div class="about-area inner-page">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-thumb mb-sm-4">
                                <img  className='rounded-2 shadow' src="assets/images/about/about-thumb.jpg" alt="" />
                                <div class="about-shape bounce-animate">
                                    <img className='rounded-2 shadow'   src="assets/images/about/about-shape.jpg" alt="" />
                                </div>
                            </div>
                            <div class="row mt-sm-5 pt-sm-5">
                                    <div class="col-lg-6 col-md-6">
                                        <div class="about-single-box">
                                            <div class="about-icon">
                                                <i class="bi bi-patch-check"></i>
                                            </div>
                                            <div class="about-text">
                                                <h4>Pour les professionnels 
                                                    du marketing</h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                        <div class="about-single-box">
                                            <div class="about-icon">
                                                <i class="bi bi-patch-check"></i>
                                            </div>
                                            <div class="about-text">
                                                <h4>Opportunité de 
                                                    marketing événementiel</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="section-title">
                                <div class="about-sub-title">
                                    <h1 className='text-primary'>CROISSANCE DES AFFAIRES</h1>
                                </div>
                                <div class="about-main-title">
                                    <h2>Connecter votre entreprise</h2>
                                    <h2>Avec le monde</h2>
                                </div>
                            </div>
                            <div class="about-content">
                                <div class="about-discribtion">
                                    <p>
                                    Il existe de nombreuses variantes de passages de lorem disponibles sur le marché libre, mais le
                                         la majorité a subi une altération sous une forme ou une autre, par injection d'humour ou par des mots aléatoires
                                    </p>
                                </div>
                                <div class="about-title">
                                    <h4>Nous concevons et construisons des sites Web puissants</h4>
                                </div>
                                <div class="about-discribtion">
                                    <p>Variations des passages de lorem sur le marché libre à disponibles, mais la majorité ont
                                         a subi une altération sous une forme ou une autre, par injection d'humour ou par des mots aléatoires</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <RaisonOfChoose />
            <Service />
            <Team />
        </div>
    )
}

export default About
