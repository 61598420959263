import React from 'react'

const Partenaires = () => {
    return (
        <div className="brand-area">
            <div className="container">
                {/* <div className="row">
                    <div className="col-lg-2">
                        <div className="text-center">
                            <div className="section-sub-title">
                                <h1 className="text-primary">Nos Projets</h1>
                            </div>
                            <div className="section-main-title">
                                <p className="fs-5 mt-sm-4">Nous proposons plusieurs solutions qui contribuent au quotidien à l'amélioration des performances des entreprises et qui impactent positivement dans la vie des populations.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="row overflow-auto flex-no-wrap text-center">
                    <div className="col-lg-2">
                        <div className="brand-thumb">
                            <img src="/assets/images/brand/brand1.svg" alt="" />
                        </div>
                    </div>
                    
                    <div className="col-lg-2">
                        <div className="brand-thumb">
                            <img src="/assets/images/brand/brand2.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="brand-thumb">
                            <img src="/assets/images/brand/brand3.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="brand-thumb">
                            <img src="/assets/images/brand/brand4.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="brand-thumb">
                            <img src="/assets/images/brand/brand5.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className="brand-thumb">
                            <img src="/assets/images/brand/brand6.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Partenaires
