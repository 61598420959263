import React from 'react'
import LogoText from '../components/LogoText'
import { Link } from 'react-router-dom'

const Navbar = () => {
    return (
        <div className="thrown-header-area style-two" id="navbar">
            <div className="container">
                <div className="row align-items-center bg-blur">
                    <div className="col-lg-3 ">
                        <div className="header-logo style-two mt-sm-2">
                           <LogoText />
                        </div>
                    </div>
                    <div className="col-lg-9">
                        <div className="ms-sm-2 thrown-menu style-two ">
                            <ul>
                                <li><a href="/">Accueil</a></li>
                                <li><Link to={"/about"}>A propos</Link></li>
                                <li><a href="/#services">Services</a></li>
                                <li><a href="/#projets">Projets</a></li>
                                {/* <li><a href="#">Plus <i className="fas fa-chevron-down"></i></a>
                                    <div className="sub-menu">
                                        <ul>
                                            <li><a href="project.html">Project</a></li>
                                        </ul>
                                    </div>
                                </li> */}
                                <li><Link to={"/contact"}>Contact</Link></li>
                            </ul>
                        </div>
                        <div className="thrown-search-button style-two">
                            <div className="thrown-social-menu style-two">
                                <ul>
                                    <li><a className="search-box-btn search-box-outer" href="#"><i className="bi bi-search"></i></a>
                                    </li>
                                </ul>
                            </div>
                            <div className="thrown-btn">
                                {/* <a className="header-button" href="contact-us.html">Get A Quout <span></span></a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="side-menu-info">
                <div className="sidebar-menu">
                    <a className="navSidebar-button" href="#"><i className="bi bi-justify-right"></i></a>
                </div>
            </div>
        </div>
    )
}

export default Navbar
