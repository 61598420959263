import React from 'react'
import Navbar from '../layouts/Navbar'
import Footer from '../layouts/Footer'
import Team from '../components/Team'
import Service from './Service'
import Partenaires from './Partenaires'
import Projects from './Projects'
import Blog from '../components/Blog'
import RaisonOfChoose from './RaisonOfChoose'
import { Link } from 'react-router-dom'
import DrawerMenu from '../components/DrawerMenu'

const Home = () => {
    return (
        <div>
            {/* <!-- Thrown Mobile Menu Area --> */}
            <div className="mobile-menu-area sticky-menu" id="sticky-mobile-menu">
                <div className="mobile-menu">
                    <div className="mobile-logo">
                        <Link to={"/"}><img width={60} src="assets/images/Logo2.png" alt="" /></Link>
                    </div>
                    <div className="side-menu-info">
                        <div className="sidebar-menu ">
                        <DrawerMenu />

                            {/* <a className="navSidebar-button" href="#"><i className="bi bi-justify-right"></i></a> */}
                        </div>
                    </div>
                </div>
            </div>

            <div className="slider-area style-four">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-6">
                            <div className="slider-four-thumb wow animate__slideInLeft">
                                <img src="assets/images/slider/slider-thumb.png" alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                            <div className="slider-content four">
                                <div className="slider-sub-title four">
                                    <h4> Meilleures solutions informatiques</h4>
                                </div>
                                <div className="slider-main-title four bg-blur">
                                    <h1>La référence et le catalyseur</h1>
                                    <h1>de l'industrie technologique</h1>
                                    <h1>au Cameroun et en Afrique.</h1>
                                </div>
                                <div className="thrown-btn slider">
                                    {/* <a className="header-button" href="contact-us.html">Get A Quout <span></span></a> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="about-area style-four">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12">
                            <div className="about-main-title style-two">
                                <h2>Rendez votre site Web plus</h2>
                                <h2>Convivial pour les moteurs de recherche</h2>
                            </div>
                            <div className="about-content">
                                <div className="about-discribtion style-two">
                                    <p>Nous développons des solutions innovantes qui résolvent les problèmes des populations et des entreprises.</p>
                                </div>
                                <div className="process-ber-plugin">
                                    <span className="process-bar">Développement de sites Web</span>
                                    <div id="bar1" className="barfiller">
                                        <div className="tipWrap" style={{ display: "inline" }}>
                                            <span className="tip"
                                                style={{ left: "334.106px", transition: "left 7s ease-in-out 0s" }}>95%</span>
                                        </div>
                                        <span className="fill" data-percentage="95"
                                            style={{ background: "rgb(22, 181, 151); width: 365.811px", transition: "width 7s ease-in-out 0s" }}></span>
                                    </div>
                                    <span className="process-bar">Big Data & Analysis</span>
                                    <div id="bar2" className="barfiller">
                                        <div className="tipWrap" style={{ display: "inline" }}>
                                            <span className="tip"
                                                style={{ left: "225.586px", transition: "left 7s ease-in-out 0s" }}>75%</span>
                                        </div>
                                        <span className="fill my-className" data-percentage="80"
                                            style={{ background: "rgb(22, 181, 151)", width: "257.944px", transition: "width 7s ease-in-out 0s" }}></span>
                                    </div>
                                    <span className="process-bar">UI/UX Design</span>
                                    <div id="bar3" className="barfiller">
                                        <div className="tipWrap" style={{ display: "inline" }}>
                                            <span className="tip"
                                                style={{ left: "225.586px", transition: "left 7s ease-in-out 0s" }}>90%</span>
                                        </div>
                                        <span className="fill my-className" data-percentage="90"
                                            style={{ background: "rgb(22, 181, 151)", width: "257.944px", transition: "width 7s ease-in-out 0s" }}></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12">
                            <div className="about-thumb four">
                                <img src="assets/images/about/about-4.jpg" alt="" />
                                <div className="about-counter four">
                                    <div className="about-countent-content four">
                                        <div className="about-counter-numbar">
                                            <h5 className="counter">12</h5>
                                        </div>
                                        <div className="about-counter-discription">
                                            <p>Années d'expérience</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="call-do-action-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-12 col-md-12">
                            <div className="single-video style-four text-center">
                                <div className="video-icon style-four">
                                    <a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true"
                                        href="https://youtu.be/BS4TUd7FJSg"><i className="bi bi-play"></i></a>
                                </div>
                            </div>
                            <div className="call-do-title text-center bg-blur">
                                <h2>Nos services les plus populaires,</h2>
                                <h2>regarder la vidéo.</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Service />
            <Partenaires />
            <Projects />

            {/* <div className="like-us-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="like-us-single-box">
                                <div className="like-us-content">
                                    <h4>100 % Compensations</h4>
                                    <p>Disruptive forces such as new technology and system integration, talent shortages, and
                                        infrastructure</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="like-us-single-box">
                                <div className="like-us-content">
                                    <h4>Experienced Team</h4>
                                    <p>Disruptive forces such as new technology and system integration, talent shortages, and
                                        infrastructure</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="like-us-single-box">
                                <div className="like-us-content">
                                    <h4>Positive Change</h4>
                                    <p>Disruptive forces such as new technology and system integration, talent shortages, and
                                        infrastructure</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Team />

            {/* <RaisonOfChoose /> */}

            {/* Blog */}
            {/* <Blog /> */}
        </div>
    )
}

export default Home
