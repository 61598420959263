import React from 'react'
import Breatcom from '../components/Breatcom'
import { SERVICES } from '../datas/dataSources'
import Partenaires from './Partenaires'

const ServiceDetails = () => {
    return (
        <div>
            <Breatcom pageName={"Détails du service"} />
            <div className="service-details-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12">
                            <div className="row">
                                <div className="col-lg-12">
                                    {/* <!-- start service details single box --> */}
                                    <div className="service-details-single-box mb-30 ">
                                        <div className="service-details-thumb">
                                            <img className='rounded-2' width={831} src="/assets/images/service/service2.jpg" alt="image" />
                                        </div>
                                        <div className="service-details-content">
                                            <h4><a href="#">Description</a></h4>
                                            <p>Le développement d'une application web vous permet de disposer d'une application qui sera accessible depuis n'importe quel navigateur sur n'importe quel appareil. Quant au développement d'application =s mobiles d'entreprise par exemple, il correspond au processus de création et de déploiement d'applications mobiles fiables, évolutives et portables, qui permettent aux grandes entreprises d'interagir avec les clients, les partenaires et les salariés.</p>
                                        </div>
                                    </div>
                                    {/* <!-- End service details single box --> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            {/* <!-- start widget search box --> */}
                            {/* <div className="widget_search box">
                                <form action="#" method="get">
                                    <input type="text" name="s" value="" placeholder="Search Here" title="Search for:" />
                                    <button type="submit" className="icons">
                                        <i className="fa fa-search"></i>
                                    </button>
                                </form>
                            </div> */}
                            {/* <!-- End widget search box --> */}
                            <div className="widget-categories-box">
                                <div className="widget-catagories-title">
                                    <h4>Liste des services</h4>
                                </div>
                                {/* <!-- widget categories menu --> */}
                                <div className="widget-categories-menu">
                                    <ul>
                                        {
                                            SERVICES.length > 0 && SERVICES.map((service) => {
                                                return (

                                                    <li key={service?.id}><a href="">{service?.title}<span><i
                                                        className="bi bi-chevron-right"></i></span></a></li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Partenaires />
        </div>
    )
}

export default ServiceDetails
