import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home"
import Contact from "./pages/Contact"
import Navbar from "./layouts/Navbar";
import Footer from "./layouts/Footer";
import ServiceDetails from "./pages/ServiceDetails";
import About from "./pages/About";

function App() {
  return (
    <div className="App">
      {/* <!-- loder --> */}
      <div className="loader_bg">
        <div className="loader"></div>
        <h4 className="loading-title">LOADING...</h4>
      </div>
      {/* mXyRZzU;rmOU */}
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path={"/"} element={<Home />}></Route>
          <Route path={"/services/details"} element={<ServiceDetails />}></Route>
          <Route path={"/contact"} element={<Contact />}></Route>
          <Route path={"/about"} element={<About />}></Route>
        </Routes>
        <Footer />
      </BrowserRouter>

      {/* <!--==================================================-  */}
      <div className="search-popup">
        <button className="close-search style-two"><span className="flaticon-multiply"><i
          className="far fa-times-circle"></i></span></button>
        <button className="close-search"><i className="fas fa-arrow-up"></i></button>
        <form method="post" action="#">
          <div className="form-group">
            <input type="search" name="search-field" value="" placeholder="Search Here" required="" />
            <button type="submit"><i className="fa fa-search"></i></button>
          </div>
        </form>
      </div>
      <div className="scroll-area">
        <div className="top-wrap">
          <div className="go-top-btn-wraper">
            <div className="go-top go-top-button">
              <i className="fas fa-arrow-up"></i>
              <i className="fas fa-arrow-up"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
