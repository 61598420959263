import React from 'react'

const RaisonOfChoose = () => {
    return (
        <div className="choose-area">
            <div className="container bg-blur">
                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="section-title">
                            <div className="section-sub-title choose">
                                <h1 className="text-primary">Nous fournissons la meilleure solution</h1>
                            </div>
                            <div className="section-main-title choose mt-sm-3">
                                <h4 className="text-white">Pourquoi devez-vous</h4>
                                <h4 className="text-white">Nous choisir ?</h4>
                            </div>
                        </div>
                        <div className="choose-discription">
                            <p>Conseil juridique, rédaction de contrats, assistance à la coordination, propriété intellectuelle
                                protection et autres soutiens juridiques pour les entreprises. Créer du contenu visuel, tel que des logos,
                                brochures, infographies et autres supports de marque.</p>
                        </div>
                        <div className="choose-single-box mb-sm-4">
                            <div className="choose-icon">
                                <img width={40} src="assets/images/resource/choose1.png" alt="" />
                            </div>
                            <div className="choose-content">
                                <h4 className="text-white">Expertise poussée</h4>
                                <p>Notre savoir-faire pointu et notre expérience approfondie garantissent des résultats de qualité supérieur.</p>
                            </div>
                        </div>
                        <div className="choose-single-box mb-sm-4">
                            <div className="choose-icon">
                                <img width={40} src="assets/images/resource/choose2.png" alt="" />
                            </div>
                            <div className="choose-content">
                                <h4 className="text-white">Proximité et flexibilité</h4>
                                <p>Notre approche personnalisée nous permet de répondre au besoins spécifiques de nos clients.</p>
                            </div>
                        </div>
                        <div className="choose-single-box mb-sm-4">
                            <div className="choose-icon">
                                <img width={40} src="assets/images/resource/choose3.png" alt="" />
                            </div>
                            <div className="choose-content">
                                <h4 className="text-white">Implémentation agile</h4>
                                <p>Notre méthode efficace assure une mise en oeuvre rapide et optimale de vos projets.</p>
                            </div>
                        </div>
                        <div className="choose-single-box mb-sm-4">
                            <div className="choose-icon">
                                <img width={40} src="assets/images/resource/choose4.png" alt="" />
                            </div>
                            <div className="choose-content">
                                <h4 className="text-white">Conception et prototypage</h4>
                                <p>Nous donnons vie à vos idées avec des solutions innovantes et fonctionnelles.</p>
                            </div>
                        </div>
                        <div className="choose-single-box mb-sm-4">
                            <div className="choose-icon">
                                <img width={40} src="assets/images/resource/choose5.png" alt="" />
                            </div>
                            <div className="choose-content">
                                <h4 className="text-white">Satisfaction et qualité</h4>
                                <p>Nous visons l'excellence pour satisfaire pleinement nos clients et leur offrir des solutions de qualité.</p>
                            </div>
                        </div>
                        <div className="text-white mb-sm-2">
                            <div className="choose-icon">
                                <img width={40} src="assets/images/resource/choose6.png" alt="" />
                            </div>
                            <div className="text-white">
                                <h4 className="text-white">Disponible 24h/24</h4>
                                <p>Nous visons l'excellence pour satisfaire pNotre équipe est à votre disposition à toutmoment pour répondre à vos besoins.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12">
                        <div className="single-video style-two">
                            <div className="video-icon style-two">
                                <a className="video-vemo-icon venobox vbox-item" data-vbtype="youtube" data-autoplay="true"
                                    href="https://youtu.be/BS4TUd7FJSg"><i className="bi bi-play"></i></a>
                            </div>
                        </div>

                        {/* Newsletter */}
                        {/* <div className="choose-contact">
                            <div className="row">
                                <div className="col-lg-4 col-md-12">
                                    <div className="subscribe-title">
                                        <h4>Subscribe Our Newslatter</h4>
                                    </div>
                                </div>
                                <div className="col-lg-8 col-md-12">
                                    <form action="https://formspree.io/f/myyleorq" method="post" id="#">
                                        <div className="row">
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                <div className="form-box">
                                                    <input type="text" name="email" placeholder="Email Address" />
                                                </div>
                                            </div>
                                            <div className="col-lg-6 col-md-6 col-sm-6 col-6">
                                                <div className="form-box-button">
                                                    <button type="Submit">Subscribe <i className="bi bi-arrow-right"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RaisonOfChoose
